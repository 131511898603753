import type { CustomFetch } from '~/shared/types/custom-fetch.type';
import type { AppLocale } from '~/data/domain/app.locale';
import type { TabItem } from '~/shared/components/lib/tabs/tab.interface';
import type {
  BackendArticleCategory,
  BackendArticleModel,
  BackendArticleModelBase,
  BackendArticlesMainPageResponse,
  FrontendArticlesMainPage,
} from '~/features/article/services/article.type';
import {
  type ArticleModel,
  type ArticleModelBase,
  createArticleModel,
  createArticleModelBase,
} from '~/features/article/models/article.model';
import type { QueryFunctionContext } from '@tanstack/vue-query';
import type { articleKeys } from '~/features/article/queries/article.query';

export const ArticleService = (fetch: CustomFetch) => ({
  async getArticlesMainPage({
    queryKey: [{ locale }],
  }: QueryFunctionContext<
    ReturnType<(typeof articleKeys)['mainPage']>
  >): Promise<FrontendArticlesMainPage> {
    const data: BackendArticlesMainPageResponse = await fetch('api/main-page/top-articles', {
      credentials: 'include',
    });

    const categoryArticles: Record<string, ArticleModelBase[]> = Object.fromEntries(
      Object.entries(data.categoryArticles).map(([categorySlug, articles]) => [
        categorySlug,
        articles.map((item) => createArticleModelBase(item, locale)),
      ]),
    );

    const categories = Object.keys(data.categoryArticles).map((categorySlug) => {
      return {
        id: categorySlug,
        label: data.categories.find((item) => item.slug === categorySlug)?.title || categorySlug,
      };
    });

    return {
      latestArticles: data.latestArticles.map((item) => createArticleModel(item, locale)),
      categoryArticles,
      categories,
    };
  },

  async getArticlesRecommended(locale: AppLocale): Promise<ArticleModel[]> {
    const articles: BackendArticleModel[] = await fetch('api/list/articles/recommended', {
      credentials: 'include',
    });

    return articles.map((item) => createArticleModel(item, locale));
  },
  async getArticlesLatest(locale: AppLocale): Promise<ArticleModel[]> {
    const articles: BackendArticleModel[] = await fetch('api/list/articles/latest', {
      credentials: 'include',
    });

    return articles.map((item) => createArticleModel(item, locale));
  },
  async getArticleBySlug(slug: string, locale: AppLocale): Promise<ArticleModel> {
    const response: BackendArticleModel = await fetch(`api/page/article/${slug}`, {
      credentials: 'include',
    });

    return createArticleModel(response, locale);
  },
  async getOtherArticles(slug: string, locale: AppLocale): Promise<ArticleModelBase[]> {
    const response: BackendArticleModelBase = await fetch(`api/page/article/${slug}/other`, {
      credentials: 'include',
    });

    return response.map((item) => createArticleModelBase(item, locale));
  },
  async getArticlesAllRecommended(locale: AppLocale): Promise<Record<string, ArticleModel[]>> {
    const response: Record<string, BackendArticleModel[]> = await fetch(
      'api/list/articles/all-recommended',
      {
        credentials: 'include',
      },
    );
    const result: Record<string, ArticleModel[]> = {};

    // Iterate through each date
    for (const date in response) {
      result[date] = response[date].map((item) => createArticleModel(item, locale));
    }

    return result;
  },
  async getArticlesAll(categoryId: string, locale: AppLocale): Promise<ArticleModel[]> {
    const articles: BackendArticleModel[] = await fetch('api/list/articles/all', {
      credentials: 'include',
      params: {
        category_id: categoryId,
      },
    });

    return articles.map((item) => createArticleModel(item, locale));
  },

  async getArticleCategories(): Promise<TabItem[]> {
    const categories: BackendArticleCategory[] = await fetch('api/list/articles/categories', {
      credentials: 'include',
    });

    return categories.map((item) => ({
      id: String(item.id),
      label: item.title,
    }));
  },

  async like(id: number, like = true) {
    const apiPath = like ? `api/v1/like/article/${id}` : `api/v1/unlike/article/${id}`;

    return await fetch(apiPath, {
      localError: true,
      method: 'POST',
    });
  },
});
