import dayjs from 'dayjs';
import type { ContentAuthor } from '~/shared/types/content.type';
import type { AppLocale } from '~/data/domain/app.locale';
import { USER_AVATAR_STUMP_PATH } from '~/shared/constants/static-images-paths.constant';
import type {
  BackendArticleModel,
  BackendArticleModelBase,
} from '~/features/article/services/article.type';

export interface ArticleModelBase {
  id: number;
  title: string;
  slug: string;
  shortDescription: string;
  image: string | null;
  author: ContentAuthor;
  publishedAt: string;
  publishedAtFormatted: string;
}

export interface ArticleModel extends ArticleModelBase {
  content: string | null;
  likes: number;
  pageviews: number;
  categoryId: number;
  isLiked: boolean;
}

export function createArticleModelBase(
  raw: BackendArticleModelBase,
  locale: AppLocale,
): ArticleModelBase {
  return {
    id: raw.id,
    title: raw.title,
    shortDescription: raw.short_description,
    image: raw.image_url || raw.image,
    publishedAt: raw.published_at,
    publishedAtFormatted: dayjs(raw.published_at).locale(locale).fromNow(),
    author: {
      image: raw.author_avatar ?? USER_AVATAR_STUMP_PATH,
      name: raw.author_name,
    },
    slug: raw.slug,
  };
}

export function createArticleModel(raw: BackendArticleModel, locale: AppLocale): ArticleModel {
  const baseModel = createArticleModelBase(raw, locale);

  return {
    ...baseModel,
    content: raw.content,
    likes: raw.likes,
    pageviews: raw.pageviews,
    categoryId: raw.category_id,
    isLiked: raw.isLiked,
  };
}
